<template>
  <div>
    <h1 class="font-zeyada text-4xl my-4 text-center">{{ recipe.titulo }}</h1>

    <div
      class="
        w-12
        h-12
        mx-auto
        my-10
        border-2 border-yellow-500
        rounded-full
        loader
      "
      v-if="!isLoaded"
    ></div>

    <TransitionRoot
      :show="isLoaded"
      enter="transition-opacity duration-500"
      enter-from="opacity-0"
      enter-to="opacity-100"
    >
      <div class="space-y-4 mb-6">
        <div class="aspect-w-4 aspect-h-3">
          <img
            class="object-cover shadow-lg rounded-lg"
            :src="API_BASE_URL + recipe?.foto?.url"
            :alt="recipe.titulo"
          />
        </div>
      </div>

      <div
        class="
          relative
          grid
          gap-6
          bg-white
          px-5
          py-6
          sm:gap-8
          sm:p-8
          md:grid-cols-2
          lg:grid-cols-3
        "
      >
        <span
          class="
            -m-3
            p-3
            flex
            items-start
            rounded-lg
            hover:bg-gray-50
            transition
            ease-in-out
            duration-150
          "
        >
          <div
            class="
              flex-shrink-0 flex
              items-center
              justify-center
              h-10
              w-10
              text-white
              sm:h-12
              sm:w-12
            "
          >
            <font-awesome-icon
              :icon="['fad', 'sitemap']"
              class="fa-fw mx-auto"
              size="2x"
              :style="{ color: '#f49b00' }"
              aria-hidden="true"
            />
          </div>
          <div class="ml-4">
            <p class="text-base font-medium text-gray-900">Categoría</p>
            <p class="mt-1 text-sm text-gray-500">
              {{ recipe.categoria?.titulo }}
            </p>
          </div>
        </span>
        <span
          class="
            -m-3
            p-3
            flex
            items-start
            rounded-lg
            hover:bg-gray-50
            transition
            ease-in-out
            duration-150
          "
        >
          <div
            class="
              flex-shrink-0 flex
              items-center
              justify-center
              h-10
              w-10
              text-white
              sm:h-12
              sm:w-12
            "
          >
            <font-awesome-icon
              :icon="['fad', 'stopwatch']"
              class="fa-fw mx-auto"
              size="2x"
              :style="{ color: '#f49b00' }"
              aria-hidden="true"
            />
          </div>
          <div class="ml-4">
            <p class="text-base font-medium text-gray-900">Preparación</p>
            <p class="mt-1 text-sm text-gray-500">
              {{ recipe.tiempo_preparacion }} min.
            </p>
          </div>
        </span>
        <span
          class="
            -m-3
            p-3
            flex
            items-start
            rounded-lg
            hover:bg-gray-50
            transition
            ease-in-out
            duration-150
          "
        >
          <div
            class="
              flex-shrink-0 flex
              items-center
              justify-center
              h-10
              w-10
              text-white
              sm:h-12
              sm:w-12
            "
          >
            <font-awesome-icon
              :icon="['fad', 'users']"
              class="fa-fw mx-auto"
              size="2x"
              :style="{ color: '#f49b00' }"
              aria-hidden="true"
            />
          </div>
          <div class="ml-4">
            <p class="text-base font-medium text-gray-900">Raciones</p>
            <p class="mt-1 text-sm text-gray-500">
              {{ recipe.raciones }}
            </p>
          </div>
        </span>
      </div>

      <article class="prose">
        <VueMarkdownIt :source="recipe.descripcion"></VueMarkdownIt>
      </article>
    </TransitionRoot>
  </div>
</template>

<script>
import { ref } from "vue";
import { TransitionRoot } from "@headlessui/vue";
import router from "@/router";
import { useRoute } from "vue-router";
import VueMarkdownIt from "vue3-markdown-it";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSitemap,
  faStopwatch,
  faUsers,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { API_BASE_URL } from "@/config/parameters";

library.add([faSitemap, faStopwatch, faUsers]);

export default {
  name: "recipe",
  components: {
    VueMarkdownIt,
    TransitionRoot,
    FontAwesomeIcon,
  },
  setup() {
    const route = useRoute();
    const recipe = ref({});
    const isLoaded = ref(false);

    fetch(
      `${API_BASE_URL}/recetas?slug=${route.params.slug}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length === 0) {
          router.push({ name: "NotFound", params: { catchAll: "404" } });
        }
        recipe.value = Object.assign({}, data[0]);
        isLoaded.value = true;
      })
      .catch((error) => console.error(error));

    return {
      API_BASE_URL,
      recipe,
      isLoaded,
    };
  },
};
</script>
